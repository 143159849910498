/*--------------------------------------------------------------
# Blog Posts
--------------------------------------------------------------*/
.blog-posts .post-box {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
  height: 100%;
  overflow: hidden;
  padding: 30px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.blog-posts .post-box .post-img {
  overflow: hidden;
  margin: -30px -30px 15px -30px;
  position: relative;
}

.blog-posts .post-box .post-img img {
  transition: 0.5s;
}

.blog-posts .post-box .post-date {
  font-size: 16px;
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
  display: block;
  margin-bottom: 10px;
}

.blog-posts .post-box .post-title {
  font-size: 24px;
  color: var(--textColor);
  font-weight: 700;
  margin-bottom: 18px;
  position: relative;
  transition: 0.3s;
}

.blog-posts .post-box .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
}

.blog-posts .post-box .readmore i {
  line-height: 0;
  margin-left: 4px;
  font-size: 18px;
}

.blog-posts .post-box:hover .post-title {
  color: var(--textHoverColor);
}

.blog-posts .post-box:hover .post-img img {
  transform: rotate(6deg) scale(1.2);
}

.blog-posts .new-blog a {
  margin: 14px 0 0 20px;
}

.blog-posts .new-blog i {
  line-height: 0;
  font-size: 30px;
  color: var(--textColor);
  transition: all 0.3s;
}

.blog-posts .new-blog i:hover {
  color: var(--textHoverColor);
}

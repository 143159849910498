.admin-entry .admin-form {
  margin-top: 10px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.admin-entry .admin-form .password {
  position: relative;
}

.admin-entry .admin-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.admin-entry .admin-form p {
  font-size: 14px;
}

.admin-entry .admin-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.admin-entry .admin-form .form-check-input {
  margin-top: 5px;
  padding: 7px;
}

.admin-entry .admin-form .form-check-input:checked {
  background-color: var(--textColor);
  color: #fff;
}

.admin-entry .admin-form input:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.admin-entry .admin-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.admin-entry .admin-form textarea:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.admin-entry .admin-form .form-group {
  margin-bottom: 25px;
}

.admin-entry .admin-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: var(--textColor);
}

.admin-entry .admin-form .btn-primary:hover {
  background-color: var(--textHoverColor);
}

.admin-entry .admin-form .change-form-link {
  margin-top: 20px;
}

.admin-entry .admin-form a {
  font-size: 14px;
  color: var(--textColor);
}

.admin-entry .admin-form a:hover {
  color: var(--textHoverColor);
}

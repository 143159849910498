/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: var(--textColor);
  min-height: 40px;
  margin-top: 126px;
  color: #fff;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 115px;
  }
}

.breadcrumbs h2 {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol a {
  color: #fff;
  transition: 0.3s;
}

.breadcrumbs ol a:hover {
  text-decoration: underline;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #8894f6;
  content: "/";
}

.breadcrumbs .logout {
  color: #ffffff;
}

.training .posts-list article {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 30px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.training .posts-list .post-img {
  max-height: 240px;
  margin: -30px -30px 15px -30px;
  overflow: hidden;
}

.training .posts-list .post-category {
  font-size: 16px;
  color: #555555;
  margin-bottom: 10px;
}

.training .posts-list .title {
  font-size: 22px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 20px 0;
}

.training .posts-list .title a {
  color: var(--textColor);
  transition: 0.3s;
}

.training .posts-list .title a:hover {
  color: var(--textHoverColor);
}

.training .posts-list .post-author-img {
  width: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.training .posts-list .post-author-list {
  font-weight: 600;
  margin-bottom: 5px;
}

.training .posts-list .post-date {
  font-size: 14px;
  color: #3c3c3c;
  margin-bottom: 0;
}

.admin-training-edit .admin-form {
  margin-top: 10px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.admin-training-edit .admin-form .password {
  position: relative;
}

.admin-training-edit .admin-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.admin-training-edit .admin-form p {
  font-size: 14px;
}

.admin-training-edit .admin-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.admin-training-edit .admin-form .form-check-input {
  margin-top: 5px;
  padding: 7px;
}

.admin-training-edit .admin-form .form-check-input:checked {
  background-color: var(--textColor);
  color: #fff;
}

.admin-training-edit .admin-form input:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.admin-training-edit .admin-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.admin-training-edit .admin-form textarea:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.admin-training-edit .admin-form select {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.admin-training-edit .admin-form select:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.admin-training-edit .admin-form .form-group {
  margin-bottom: 25px;
}

.admin-training-edit .admin-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: var(--textColor);
}

.admin-training-edit .admin-form .btn-primary:hover {
  background-color: var(--textHoverColor);
}

.admin-training-edit .admin-form .change-form-link {
  margin-top: 20px;
}

.admin-training-edit .admin-form a {
  font-size: 14px;
  color: var(--textColor);
}

.admin-training-edit .admin-form a:hover {
  color: var(--textHoverColor);
}

.admin-training-edit .tag-icon {
  list-style: none;
  padding: 4px 15px;
  background: var(--textColor);
  border-radius: 50px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
  transition: ease-in-out 0.3s;
}

.admin-training-edit .tag-icon:hover {
  background: var(--textHoverColor);
  cursor: pointer;
}

.admin-training-edit .progress {
  height: 10px;
}

.admin-training-edit .section-icons i {
  margin-right: 10px;
}

.admin-training-edit i:hover {
  color: var(--textHoverColor);
}

.admin-training-edit .clipboard,
.admin-training-edit .add-images {
  position: relative;
}

.admin-training-edit .clipboard i,
.admin-training-edit .add-images i {
  position: absolute;
  font-size: 14px;
  font-weight: 500;
  top: 50%;
  right: 2%;
  transform: translateY(-50%);
}

.admin-training-edit .avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

/*--------------------------------------------------------------
# Our admin Section
--------------------------------------------------------------*/
.admin {
  padding: 60px 0;
}

.admin .admin-item {
  padding: 40px;
  background: #fff;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.admin .admin-item .icon {
  width: 48px;
  height: 48px;
  position: relative;
  margin-bottom: 50px;
}

.admin .admin-item .icon i {
  color: var(--textColor);
  font-size: 56px;
  transition: ease-in-out 0.3s;
  z-index: 2;
  position: relative;
}

.admin .admin-item .icon:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #eeeeee;
  border-radius: 50px;
  z-index: 1;
  top: 10px;
  right: -20px;
  transition: 0.3s;
}

.admin .admin-item h3 {
  color: var(--textColor);
  font-weight: 700;
  margin: 0 0 20px 0;
  padding-bottom: 8px;
  font-size: 22px;
  position: relative;
  display: inline-block;
  border-bottom: 4px solid #eeeeee;
  transition: 0.3s;
}

.admin .admin-item p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.admin .admin-item .readmore {
  margin-top: 15px;
  display: inline-block;
  color: var(--textColor);
}

.admin .admin-item:hover .icon:before {
  background: var(--textHoverColor);
}

.admin .admin-item:hover h3 {
  border-color: var(--textHoverColor);
}

.quotation .quotation-form {
  margin-top: 10px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.quotation .quotation-form .password {
  position: relative;
}

.quotation .quotation-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.quotation .quotation-form h5 {
  font-weight: bold;
  font-size: 18px;
  color: var(--textColor);
}

.quotation .quotation-form p {
  font-size: 14px;
}

.quotation .quotation-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.quotation .quotation-form .form-check-input {
  margin-top: 5px;
  padding: 7px;
}

.quotation .quotation-form .form-check-input:checked {
  background-color: var(--textColor);
  color: #fff;
}

.quotation .quotation-form input:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.quotation .quotation-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.quotation .quotation-form textarea:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.quotation .quotation-form select {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.quotation .quotation-form select:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.quotation .quotation-form .form-group {
  margin-bottom: 25px;
}

.quotation .quotation-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: var(--textColor);
}

.quotation .quotation-form .btn-primary:hover {
  background-color: var(--textHoverColor);
}

.quotation .quotation-form .change-form-link {
  margin-top: 20px;
}

.quotation .quotation-form a {
  font-size: 14px;
  color: var(--textColor);
}

.quotation .quotation-form a:hover {
  color: var(--textHoverColor);
}

.quotation .tag-icon {
  list-style: none;
  padding: 4px 15px;
  background: var(--textColor);
  border-radius: 50px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
  transition: ease-in-out 0.3s;
}

.quotation .tag-icon:hover {
  background: var(--textHoverColor);
  cursor: pointer;
}

.quotation .progress {
  height: 10px;
}

.quotation .section-icons i {
  margin-right: 10px;
}

.quotation i:hover {
  color: var(--textHoverColor);
}

.quotation .quotation-form .form-select {
  color: #212529;
}

.portfolios-mgmt .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(255, 255, 255, 0.75);
}

.portfolios-mgmt .portfolio-wrap::before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolios-mgmt .portfolio-wrap img {
  transition: 1s;
}

.portfolios-mgmt .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolios-mgmt .portfolio-wrap .portfolio-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid rgba(1, 41, 112, 0.2);
  border-left: 3px solid rgba(1, 41, 112, 0.2);
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolios-mgmt .portfolio-wrap .portfolio-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid rgba(1, 41, 112, 0.2);
  border-right: 3px solid rgba(1, 41, 112, 0.2);
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolios-mgmt .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #012970;
  font-weight: 700;
}

.portfolios-mgmt .portfolio-wrap .portfolio-info p {
  color: #012970;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.portfolios-mgmt .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolios-mgmt .portfolio-wrap .portfolio-links a {
  color: #fff;
  background: #4154f1;
  margin: 10px 2px;
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s;
}

.portfolios-mgmt .portfolio-wrap .portfolio-links a i {
  font-size: 24px;
  line-height: 0;
}

.portfolios-mgmt .portfolio-wrap .portfolio-links a:hover {
  background: #5969f3;
}

.portfolios-mgmt .portfolio-wrap:hover img {
  transform: scale(1.1);
}

.portfolios-mgmt .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolios-mgmt .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

.portfolios-mgmt .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}

.portfolios-mgmt .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}

.portfolios-mgmt .new-portfolio a {
  margin: 14px 0 0 20px;
}

.portfolios-mgmt .new-portfolio i {
  line-height: 0;
  font-size: 30px;
  color: var(--textColor);
  transition: all 0.3s;
}

.portfolios-mgmt .new-portfolio i:hover {
  color: var(--textHoverColor);
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  background: #fff;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  height: 44px;
  padding: 0;
  transition: all 0.5s;
  overflow: hidden;
  z-index: 996;
}

#topbar.topbar-scrolled {
  top: -44px;
}

#topbar .contact-info i {
  font-style: normal;
  color: var(--textColor);
}

#topbar .contact-info i a,
#topbar .contact-info i span {
  padding-left: 5px;
  color: #444444;
}

#topbar .contact-info i a {
  line-height: 0;
  transition: 0.3s;
}

#topbar .contact-info i a:hover {
  color: var(--textHoverColor);
}

#topbar .social-links a {
  color: var(--textColor);
  padding: 4px 12px;
  display: inline-block;
  line-height: 1px;
  transition: 0.3s;
  border-left: 1px solid #e9e9e9;
}

#topbar .social-links a:hover {
  color: var(--textHoverColor);
}

#topbar .social-links a:first-child {
  border-left: 0;
}

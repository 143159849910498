@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;800;900&display=swap");

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

:root {
  --primaryColor: #4154f1;
  --primaryHoverColor: #717ff5;
  --themeBgColor: #f9f7fe;
  --textColor: #013289;
  --textHoverColor: #4154f1;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  color: #444444;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  color: var(--primaryColor);
  text-decoration: none !important;
}

a:hover {
  color: var(--primaryHoverColor);
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

.queries-mgmt .table thead {
  color: var(--textColor);
}
.queries-mgmt .table i {
  cursor: pointer;
  color: var(--textColor);
}

.queries-mgmt .table i:hover {
  color: var(--textHoverColor);
}

.show-pws {
  position: absolute;
  color: #a9a9a9;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.show-pws.active {
  color: var(--primaryHoverColor);
}

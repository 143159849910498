.modal .modal-title {
  color: var(--textColor);
}

.modal .btn-modal {
  line-height: 0;
  padding: 20px 20px;
  border: none;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  cursor: pointer;
  background: var(--textColor);
  box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);
}

.modal .btn-modal:hover {
  color: #fff;
  background: var(--textHoverColor);
}

.modal .btn-modal-secondary {
  line-height: 0;
  padding: 20px 20px;
  border: none;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  cursor: pointer;
  background: #424242;
  box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);
}

.modal .btn-modal-secondary:hover {
  background: #000000;
}

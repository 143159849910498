/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq .accordion-collapse {
  border: 0;
}

.faq .accordion-button {
  padding: 15px 15px 20px 0;
  font-weight: 600;
  border: 0;
  font-size: 18px;
  color: #444444;
  text-align: left;
}

.faq .accordion-button:focus {
  box-shadow: none;
}

.faq .accordion-button:not(.collapsed) {
  background: none;
  color: #4154f1;
  border-bottom: 0;
}

.faq .accordion-body {
  padding: 0 0 25px 0;
  border: 0;
}

.admin-content-mgmt .admin-form {
  margin-top: 10px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.admin-content-mgmt .admin-form .password {
  position: relative;
}

.admin-content-mgmt .admin-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.admin-content-mgmt .admin-form p {
  font-size: 14px;
}

.admin-content-mgmt .admin-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.admin-content-mgmt .admin-form .form-check-input {
  margin-top: 5px;
  padding: 7px;
}

.admin-content-mgmt .admin-form .form-check-input:checked {
  background-color: var(--textColor);
  color: #fff;
}

.admin-content-mgmt .admin-form input:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.admin-content-mgmt .admin-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.admin-content-mgmt .admin-form textarea:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.admin-content-mgmt .admin-form select {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.admin-content-mgmt .admin-form select:focus {
  box-shadow: none;
  border-color: #a0aaf8;
}

.admin-content-mgmt .admin-form .form-group {
  margin-bottom: 25px;
}

.admin-content-mgmt .admin-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: var(--textColor);
}

.admin-content-mgmt .admin-form .btn-primary:hover {
  background-color: var(--textHoverColor);
}

.admin-content-mgmt .admin-form .change-form-link {
  margin-top: 20px;
}

.admin-content-mgmt .admin-form a {
  font-size: 14px;
  color: var(--textColor);
}

.admin-content-mgmt .admin-form a:hover {
  color: var(--textHoverColor);
}

.admin-content-mgmt .tag-icon {
  list-style: none;
  padding: 4px 15px;
  background: var(--textColor);
  border-radius: 50px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
  transition: ease-in-out 0.3s;
}

.admin-content-mgmt .tag-icon:hover {
  background: var(--textHoverColor);
  cursor: pointer;
}

.admin-content-mgmt .progress {
  height: 10px;
}

.admin-content-mgmt .section-icons i {
  margin-right: 10px;
}

.admin-content-mgmt i:hover {
  color: var(--textHoverColor);
}

.admin-content-mgmt .nav-link {
  background: none !important;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  color: #012970;
  padding: 12px 0;
  margin-right: 25px;
  margin-bottom: -2px;
  border-radius: 0;
}

.admin-content-mgmt .nav-link.active {
  color: var(--textHoverColor) !important;
  border-bottom: 3px solid var(--textHoverColor);
}

.admin-content-mgmt .tab-content h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 700;
  color: var(--textColor);
}

.admin-content-mgmt .tab-content i {
  font-size: 24px;
  line-height: 0;
  margin-right: 8px;
  color: var(--textHoverColor);
}

.admin-content-mgmt .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: var(--textColor);
}

.admin-content-mgmt .btn-primary:hover {
  background-color: var(--textHoverColor);
}
